'use strict';

import CnSmartFtp from './cn-smart-ftp';
import CnSmartFtpLite from './cn-smart-ftp-lite';
import CnSmartForm from './cn-smart-form';
import CnSmartDetail from './cn-smart-detail';
import CnSmartPrimarySub from './cn-smart-primarySub';

// 通过arr控制输出的顺序
var bundle = [
  CnSmartFtp,
  CnSmartForm,
  CnSmartDetail,
  CnSmartFtpLite,
  CnSmartPrimarySub
];

export default bundle;
export {
  CnSmartFtp,
  CnSmartForm,
  CnSmartDetail,
  CnSmartFtpLite,
  CnSmartPrimarySub
};
